import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from 'features/authentication'

export const withRouter = (component: () => React.ReactNode) => () => (
  <AuthProvider>
    <BrowserRouter>
      <Suspense fallback="Loading...">
        {component()}
      </Suspense>
    </BrowserRouter>
  </AuthProvider>
);
