import React from 'react';

interface IContainer {
  as?: React.ElementType
  children: React.ReactNode
  className?: string
}

export const Container: React.FC<IContainer> = ({ children, className, as = 'div' }) => {
  const ElementType = as;

  return <ElementType className={className}>{children}</ElementType>;
};
