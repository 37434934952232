import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import {
  Form,
  Field,
  Input,
  composeValidators,
  emailPreset,
  required,
} from '@maksze/form'
import { inputClasses } from 'shared/form'
import { Button } from '@maksze/button'
import { H2 } from 'shared/ui'
import { TLoginData, useAuth } from '../index'

const LoginFormUI: React.FC<{ handleSubmit(): void }> = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        {...emailPreset()}
        classes={inputClasses}
        label="Ваш email"
      />

      <Field
        type="password"
        classes={inputClasses}
        component={Input}
        name="password"
        placeholder="***"
        validate={composeValidators(required)}
        label="Пароль"
      />

      <Button type="submit">Войти</Button>
    </form>
  )
}
export const LoginForm: React.FC<{}> = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState<boolean>(false)

  const onSubmit = async (data: TLoginData) => {
    setError(false)

    try {
      await auth.signin(data, () => {
        navigate('/', { replace: true });
      })
    } catch (e) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
  }

  return (
    <div className="flex justify-center">
      <div className="w-96">
        <H2>Вход</H2>

        {error && (
          <b className="text-danger-500">Ошибка! Проверьте логин/пароль или обратитесь к администратору</b>
        )}

        <Form
          onSubmit={onSubmit}
          render={LoginFormUI}
        />
      </div>
    </div>
  )
}
