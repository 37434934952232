import React from 'react';
import { Container } from 'shared/ui'
import { useAuth } from 'features/authentication'
import { Button } from '@maksze/button'
import { Link } from 'react-router-dom'

interface IHasChildren {
  children: React.ReactNode
}

interface ILayout extends IHasChildren {
  withFooter?: boolean
  withHeader?: boolean
}

export const Layout: React.FC<ILayout> = ({ children, withFooter = true, withHeader = true }) => {
  return (
    <div className="bg-secondary-200 flex flex-col min-h-screen">
      {withHeader && (<Header />)}

      <main className="flex-grow">{children}</main>

      {withFooter && (<Footer />)}
    </div>
  )
}

const Header: React.FC = () => {
  const auth = useAuth()

  return (
    <Container as="header" className="p-4 text-center flex justify-between">
      <div className="text-left">
        <div className="text-2xl">Sushipizza74</div>
        <div className="text-sm">tillypad-тренажер</div>
      </div>

      {auth.user && (
        <nav className="flex self-center">
          <Link to="/">Уроки</Link>

          {false && auth.isAdmin && (
            <Link to="/users" className="ml-2">Пользователи</Link>
          )}

          <Button
            onClick={() => auth.signout(() => { })}
            className="ml-2"
            size={300}
          >Выйти</Button>
        </nav>
      )}
    </Container>
  )
}

const Footer: React.FC = () => {
  const auth = useAuth()

  return (
    <Container as="footer" className="p-4 text-center flex justify-between">
      <div className="text-left">
        <div className="text-2xl">Sushipizza74</div>
        <div className="text-sm">tillypad-тренажер</div>
      </div>

      {auth.user && (
        <nav className="flex self-center">
          <Link to="/">Уроки</Link>

          {false && auth.isAdmin && (
            <Link to="/users" className="ml-2">Пользователи</Link>
          )}

          <Button
            onClick={() => auth.signout(() => { })}
            className="ml-2"
            size={300}
          >Выйти</Button>
        </nav>
      )}
    </Container>
  )
}
