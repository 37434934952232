import { lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';

import { Spinner } from 'shared/ui';

const Home = lazy(() => import("./home"));
const Login = lazy(() => import("./login"));
const Lesson = lazy(() => import("./lesson"));
const LessonCreate = lazy(() => import("./lesson-create"));
const LessonEdit = lazy(() => import("./lesson-edit"));
const Users = lazy(() => import("./users"));

export const Routing = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/lesson/create" element={<LessonCreate />} />
        <Route path="/lesson/:id" element={<Lesson />} />
        <Route path="/lesson/:id/edit" element={<LessonEdit />} />
        <Route path="/users" element={<Users />} />
      </Routes>
    </Suspense>
  );
};
