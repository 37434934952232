import axios from 'axios'
import { TLoginData } from '../index'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const apiSignin = async (data: TLoginData): Promise<void> => {
  const result = await axios.post(`${API_BASE_URL}/api/v1/login`, {
    username: data.email,
    password: data.password
  })

  return result.data
}

export const apiSignout = async (): Promise<void> => {
  await axios.get(`${API_BASE_URL}/api/v1/logout`)
}
