export const inputClasses = {
  wrap: 'my-4 flex flex-col',
  wrapHasError: 'text-danger-500',
  field: 'p-2',
  label: 'mb-2',
  error: 'text-danger-500 border-danger-500',
  size: {
    500: 'text-base'
  },
  type: {
    text: 'form-input type--text',
    checkbox: ''
  },
  components: {
    error: 'text-danger-500'
  }
}


