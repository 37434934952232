import { createContext, useContext, useState, useEffect } from 'react'
import { TLoginData, apiSignin, apiSignout } from '.';

interface AuthContextType {
  user: any;
  signin: (cred: TLoginData, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
  isAdmin: boolean;
}

export let AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const newUser = localStorage.getItem('user')

    if (newUser) {
      setUser(JSON.stringify(newUser))
    }
  }, [])

  const signin = async (cred: TLoginData, callback: VoidFunction) => {
    const newUser = await apiSignin(cred)
    localStorage.setItem('user', JSON.stringify(newUser));
    setUser(newUser);
    callback();
  };

  const signout = async (callback: VoidFunction) => {
    localStorage.removeItem('user');
    setUser(null);
    await apiSignout()
    callback();
  };

  const isAdmin = user?.roles?.includes('ROLE_ADMIN')

  let value = { user, signin, signout, isAdmin };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


export const useAuth = () => {
  return useContext(AuthContext);
}
